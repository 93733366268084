/* eslint-disable */
import { useContext, useEffect, useState } from "react";
import { v4 as uuid } from 'uuid';
import './myvnp.css';
import { Link } from "react-router-dom";
import FeedbackContext from '../context/FeedbackContext'
import {
    Button,
    ButtonGroup,
    Col,
    Row,
    Container,
    Form,
    Spinner,
    Card,
    ListGroup,
    Modal,
    FloatingLabel,
    Table,
    Stack,
    Dropdown
} from "react-bootstrap";

function Form_log_checkin_nhan_hang({history}) {
    
    const { removeAccents, userLogger, loading, SetLoading, formatDate, alert, alertText, alertType, SetALert, SetALertText, SetALertType } = useContext(FeedbackContext)

    const fetch_initial_data = async (manv) => {
        SetLoading(true)
        // const response = await fetch(`https://bi.meraplion.com/local/form_data_log/?manv=${manv}`)
        const response = await fetch(`https://bi.meraplion.com/local/form_data_log/?manv=MR3055`)
        
        if (!response.ok) {
            SetLoading(false)
        }

        else {
        const data = await response.json()
        // const data = data_arr[0]
        set_data_table(data['danhsach'])
        console.log(data)
        SetLoading(false)

        }
    }

    useEffect(() => {
        if (localStorage.getItem("userInfo")) {
        const media = window.matchMedia('(max-width: 960px)');
        const isMB = (media.matches);
        const dv_width = window.innerWidth;
        userLogger(JSON.parse(localStorage.getItem("userInfo")).manv, 'Form_log_checkin_nhan_hang', isMB, dv_width);
        set_manv(JSON.parse(localStorage.getItem("userInfo")).manv);
        fetch_initial_data( JSON.parse(localStorage.getItem("userInfo")) );

        navigator.geolocation.getCurrentPosition(position => {
            const { latitude, longitude } = position.coords;
            setInitialPosition([latitude, longitude]);
            console.log([latitude, longitude])
    
        });


        } else {
            history.push('/login?redirect=/formcontrol/form_log_checkin_nhan_hang');
        };
    }, []);

    const [manv, set_manv] = useState("");
    const [initialPosition, setInitialPosition] = useState([0,0]);
    const [selectedFile, setSelectedFile] = useState([]);

    const [ghi_chu_chenh_lech, set_ghi_chu_chenh_lech] = useState(['Chưa xác định nguyên nhân', 'Nhận thiếu tại kho', 'Giao nhầm điểm trước']);
    const [sl_kien_thuc_nhan, set_sl_kien_thuc_nhan] = useState("");
    const [sl_tui_thuc_nhan, set_sl_tui_thuc_nhan] = useState("");
    const [sl_thung_thuc_nhan, set_sl_thung_thuc_nhan] = useState("");
    // const [lst_hinh_thuc_thau, set_lst_hinh_thuc_thau] = useState([]);



    const [data_table, set_data_table] = useState ([])

    const choose_images = (e) => {
        console.log( Array.from(e.target.files) );

        setSelectedFile([...selectedFile, ...e.target.files]);
    }

    const handle_click_xoa_file = (fileName) => {
        setSelectedFile((prevFiles) =>
            prevFiles.filter((file) => file.name !== fileName)
          );
    };    

    const clear_data = () => {
        // setSelectedFile([]);
        // setSelectedFile_2([]);
        // set_chon_ma_kh("");
    }

    const post_form_data = async (data) => {
        SetLoading(true)
        const response = await fetch(`https://bi.meraplion.com/local/template/`, {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            SetLoading(false);
            const data = await response.json();
            console.log(data);
        } else {
            SetLoading(false);
            const data = await response.json();
            console.log(data);
            SetALert(true);
            SetALertType("alert-warning");
            SetALertText("ĐÃ TẠO THÀNH CÔNG");
            setTimeout(() => SetALert(false), 3000);
            clear_data();

        }
    }

    const handle_submit = (e) => {
        e.preventDefault();
        const current_date = formatDate(Date());

        const lst_value_dd1 = []
        for (const i of lst_dd1) {
            if (i.checked === true) {lst_value_dd1.push(i.id)}
        };
        
        console.log("lst_value_dd1", lst_value_dd1);
        const data = {
            "manv":manv,
            "current_date":current_date,
            "text":text,
            "list_item": lst_item,
            "lst_value_dd1": lst_value_dd1
        }
        console.log(data);
        post_form_data(data);
        set_text("");
    }

    if (!loading) {
        return (
        <Container className="bg-teal-100 h-100" fluid>
            <Row className="justify-content-center">
                <Col md={5} >

                    <div>
        

                        {/* ALERT COMPONENT */}
                        {alert &&
                        <div className={`alert ${alertType} alert-dismissible mt-2`} role="alert" >
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                            </button>
                            <span><strong>Cảnh Báo:  </strong>{alertText}</span>
                        </div>
                        }

                        <Form onSubmit={handle_submit}>
                        {/* START FORM BODY */}
                        <h3>FORM GHI NHẬN HÀNG HÓA LOG</h3>

                        <div style={{ overflowX: 'auto' }}>
                        <Table striped bordered hover style={{ tableLayout: 'fixed', backgroundColor: '#f0f8ff' }} >
                            <thead>
                            <tr style={{ padding: '5px', fontSize: '12px' }}>
                                <th style={{ width: '100px' }} >Thả C1</th>
                                <th style={{ width: '100px' }} >Nhận C1</th>
                                <th style={{ width: '100px' }} >Thả C2</th>
                                <th style={{ width: '100px' }} >Nhận C2</th>
                                <th style={{ width: '100px' }}>Mã CT</th>
                                {/* <th>Column 5</th>
                                <th>Column 6</th> */}
                                {/* Add more columns as needed */}
                            </tr>
                            </thead>
                            <tbody>
                            {data_table                            
                            .map( (el) =>
                            <tr style={{ padding: '5px', fontSize: '12px' }}>
                                <td className="wrap-text" >{el.nhan_vien_tha_hang_1}</td>
                                <td className="wrap-text" >{el.nguoi_nhan_chang_1}</td>
                                <td className="wrap-text" >{el.nhan_vien_tha_hang_2}</td>
                                <td className="wrap-text" >{el.nguoi_nhan_chang_2}</td>
                                <td className="wrap-text" >{el.ma_chung_tu}</td>
                                {/* <td>Data 5</td>
                                <td>Data 6</td> */}
                                {/* Add more data as needed */}
                            </tr>
                            )
                            }
                            {/* Add more rows as needed */}
                            </tbody>
                        </Table>
                        </div>
                        
                        {/* NUMBER */}
                        <FloatingLabel label="SL kiện thực nhận" className="border rounded mt-2" > <Form.Control required type="number" className="" placeholder="" onChange={ (e) => set_sl_kien_thuc_nhan(e.target.value) } value = {sl_kien_thuc_nhan} /> </FloatingLabel>
                        <FloatingLabel label="SL túi thực nhận" className="border rounded mt-2" > <Form.Control required type="number" className="" placeholder="" onChange={ (e) => set_sl_tui_thuc_nhan(e.target.value) } value = {sl_tui_thuc_nhan} /> </FloatingLabel>
                        <FloatingLabel label="SL thùng thực nhận" className="border rounded mt-2" > <Form.Control required type="number" className="" placeholder="" onChange={ (e) => set_sl_thung_thuc_nhan(e.target.value) } value = {sl_thung_thuc_nhan} /> </FloatingLabel>

                        {/* <FloatingLabel label="Ghi chú chênh lệch" className="border rounded mt-2" > <Form.Control required type="text" className="" placeholder="" onChange={ (e) => set_ghi_chu_chenh_lech(e.target.value) } value = {ghi_chu_chenh_lech}/> </FloatingLabel> */}
                        
                        <Form.Select required className="mt-2" style={{height:"60px"}}  onChange={ e => set_hinh_thuc_thau(e.target.value) }>
                            <option value="">Ghi chú trên lệch</option>
                            {ghi_chu_chenh_lech
                            .map( (el, index) => 
                            <option value={el}>{el}</option>
                            )
                            }
                        </Form.Select>
                        
                        <Card className="mt-2 border-success" style={{ borderWidth: "0.1rem" }}>
                        <Card.Title >Upload ít nhất 02 Hình Ảnh nhận hàng*</Card.Title>
                        <Card.Text >Tọa độ hiện tại của bạn {initialPosition[0]}-{initialPosition[1]} </Card.Text>
                        <Form.Control id='customFileInput' required type="file" accept="image/*" capture="environment" style={{width: "115px", fontWeight: "bold", display: "none" }} multiple={true} onChange={ (e) => choose_images(e) } ></Form.Control>
                        <Button style={{ width: '16rem' }} size="sm" variant="secondary" onClick={() => document.getElementById("customFileInput").click()}>
                            CHỌN HÌNH
                        </Button>
                        <h5 className="ml-1 mt-1 text-wrap">{Array.from(selectedFile).length} hình đã up</h5>
                        
                        {Array.from(selectedFile)
                        .map( (file, index) =>
                            <ListGroup horizontal key={index}>
                            <ListGroup.Item className="text-wrap">{file.name}</ListGroup.Item>
                            <ListGroup.Item>
                            <Button className="" size="sm" variant="danger" onClick={ () => handle_click_xoa_file(file.name) }> Xóa </Button>
                            </ListGroup.Item>
                            </ListGroup>                            
                        )
                        }
                        </Card>

                        <Button disabled={true} className='mt-2' variant="warning" type="submit" style={{width: "100%", fontWeight: "bold"}}> LƯU THÔNG TIN </Button>
                        
                        </Form>
                        {/* END FORM BODY */}

                    </div>
                </Col>
            </Row>
        </Container>
        )
    }
    else {
        return (
    
            <div>
                <h1 className="text-danger text-center">Xử Lý Thông Tin</h1>
                <Spinner animation="border" role="status" style={{ height: "100px", width: "100px", margin: "auto", display: "block" }}>
                </Spinner>
            </div>
            
        )
    }

}

export default Form_log_checkin_nhan_hang

// https://www.geeksforgeeks.org/how-to-stretch-flexbox-to-fill-the-entire-container-in-bootstrap/

// const on_click_them_san_pham = (e) => {
// const arr2 = [...lst_item];
// const data = {
// "id":sp_id,
// "name":sp_sl,
// }
// arr2.push(data);
// set_lst_item(arr2);
// }

// const on_click_xoa_san_pham = (idx) => {
// console.log(idx);
// const arr2 = lst_item.filter( (_, index) => index !== idx)
// set_lst_item(arr2);
// }

// const on_click_xoa_san_pham = (idx) => {
//     console.log(idx);
//     const arr2 = lst_item.filter( (_, index) => index !== idx)
//     set_lst_item(arr2);
// }
